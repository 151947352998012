import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';

import Header from "./header";
import Footer from "./footer";
import "../../static/styles/main.scss"

const Layout = ({bodyClass, children}) => {
    
    // const site = data.allGhostSettings.edges[0].node

    return (

    <StaticQuery query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `} render={data => (
          <>
            <ThemeProvider theme={theme}>
              <Helmet>
                <body className={bodyClass} />
              </Helmet>
              <Header siteTitle={data.site.siteMetadata.title} />
              <main >{children}</main>
              <Footer />
            </ThemeProvider>
          </>
        )} />
)
    }

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout