import React from "react"
import Layout from "../components/layout"
import {Helmet} from 'react-helmet';

const ContactPage = () => (
    <Layout>
        <Helmet>
            <title>Managers App Support</title>
            <meta name="description" content="Need help with your user account, have questions about how to use ManagersApp? We are here to help"></meta>
        </Helmet>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>Support</h1>
                </div>
                <div className={"row"}>
                    <div className={"col-7"}>
                        <p>If you need help with your user account, have questions about how to use ManagersApp, or are experiencing technical difficulties, please do not hesitate to contact us. We enjoy receiving feedback on our services. </p>
                    </div>

                    <div className={"col-5"}>
                        <div className={"contact-items"}>
                            <p>Email is the best way to contact us.</p>
                            <h2>support@managers.app</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default ContactPage
